const Header = (props) => {
  const { text } = props;
  return (
    <div className="Header">
      <div className="caption">
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Header;

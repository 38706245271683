import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import VideoContainer from '../../components/VideoContainer/VideoContainer';
import Header from '../../components/Header/Header';

const Admin = ({ setCirclesData }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [error, setError] = useState(false);
  const [circles, setCircles] = useState([]);
  const navigate = useNavigate(); // Инициализируем navigate

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file);
      setCircles([]);
    } else {
      setError(true);
      setVideoFile(null);
    }
  };

  const circleDelete = (circleIndex) => {
    setCircles(circles.filter((_, index) => index !== circleIndex));
  };

  const handleCirclePlay = (index, e) => {
    const file = e.target.files[0];
    setCircles(
      circles.map((circle, i) =>
        i === index ? { ...circle, videoFile: file } : circle
      )
    );
  };

  const saveData = () => {
    if (videoFile && circles.length > 0) {
      // Сохраняем данные и передаем в App.js через setCirclesData
      setCirclesData({ videoFile, circles });
      navigate('/');
    } else {
      alert('Пожалуйста, загрузите видео и добавьте хотя бы один круг.');
    }
  };

  return (
    <div className="Admin">
      <Header text="upload video" />
      <div className="base-video">
        <VideoContainer
          videoFile={videoFile}
          error={error}
          circles={circles}
          setCircles={setCircles}
        />
        <input
          id="file-upload"
          type="file"
          accept="video/*"
          onChange={handleFileChange}
        />
      </div>
      <section className="sub-video">
        <ul>
          {circles &&
            circles.map((circle, index) => (
              <li key={index}>
                <div className="sub-video input">
                  {index + 1}
                  <input
                    id="file-upload"
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleCirclePlay(index, e)}
                  />
                  <button onClick={() => circleDelete(index)}>X</button>
                </div>
              </li>
            ))}
        </ul>
      </section>
      <button className="submit" onClick={saveData}>
        Upload
      </button>
    </div>
  );
};

export default Admin;

import { Link } from 'react-router-dom';
import { TbX, TbIndentDecrease } from 'react-icons/tb';
import './Menu.css'; // CSS для стилей

const Menu = ({ closeMenu, menuOpen }) => {
  return (
    // <div className="menu">
    <div className={menuOpen ? 'menu open' : 'menu'}>
      <button className="close-button" onClick={closeMenu}>
        <span className="rectangle"></span>
        <span className="circle">
          <TbIndentDecrease />
        </span>
      </button>
      <nav>
        <ul>
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/admin" onClick={closeMenu}>
              Upload
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;

import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';

const Home = ({ circlesData }) => {
  console.log('Переданные данные в Home:', circlesData);
  // console.log(circlesData);

  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (circlesData && circlesData.videoFile) {
      const url = URL.createObjectURL(circlesData.videoFile);
      setVideoUrl(url);

      // Освобождаем память при изменении видео
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [circlesData]);

  // Функция для обработки клика на круге
  const handleCircleClick = (circle) => {
    if (circle.videoFile) {
      const url = URL.createObjectURL(circle.videoFile);
      setVideoUrl(url);
      console.log('Загружено новое видео для круга:', url, circle.videoFile); // Выводим файл видео для проверки
    } else {
      console.log('Нет привязанного видео для этого круга');
    }
  };

  return (
    <div className="home">
      <Header text="Home" />
      {/* <h1>Home Page</h1> */}
      <div className="video-container">
        {videoUrl ? (
          <video height="600" autoPlay>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>Видео не загружено</p>
        )}

        {/* Отображение кругов */}
        {circlesData?.circles?.map((circle, index) => (
          <div
            key={index}
            className="circle"
            style={{
              top: `${circle.y - circle.radius}px`,
              left: `${circle.x - circle.radius}px`,
            }}
            onClick={() => handleCircleClick(circle)}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;

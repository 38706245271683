import { useState, useEffect, useRef } from 'react';

const VideoContainer = ({ videoFile, error, circles, setCircles }) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const radius = 30;

  useEffect(() => {
    if (videoFile) {
      const url = URL.createObjectURL(videoFile);
      setVideoUrl(url);

      if (videoRef.current) {
        videoRef.current.load();
      }

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [videoFile]);

  // Добавление нового круга при касании или клике на видео
  const handleAddCircle = (e) => {
    e.preventDefault(); // Останавливаем стандартное поведение (пауза/плей)
    e.stopPropagation(); // Предотвращаем распространение события

    // Получаем координаты клика/касания
    const container = containerRef.current.getBoundingClientRect();
    const x = e.clientX
      ? e.clientX - container.left
      : e.touches[0].clientX - container.left;
    const y = e.clientY
      ? e.clientY - container.top
      : e.touches[0].clientY - container.top;

    // Добавляем новый круг
    const newCircle = { x, y, radius, videoFile: null };
    setCircles([...circles, newCircle]);
  };

  return (
    <div className="VideoContainer" ref={containerRef}>
      {videoUrl && !error ? (
        <>
          <video
            ref={videoRef}
            controls
            onClick={handleAddCircle} // Для ПК (click)
            onTouchStart={handleAddCircle} // Для мобильных устройств (touchstart)
            onMouseDown={(e) => e.preventDefault()} // Предотвращаем поведение при клике мышью
          >
            <source src={videoUrl} type={videoFile?.type || 'video/mp4'} />
            Ваш браузер не поддерживает воспроизведение видео.
          </video>

          {/* Рендерим круги как DOM-элементы */}
          {circles.map((circle, index) => (
            <div
              key={index}
              className="circle"
              style={{
                top: `${circle.y - radius}px`,
                left: `${circle.x - radius}px`,
              }}
              onClick={() => {
                if (circle.videoFile) {
                  const url = URL.createObjectURL(circle.videoFile);
                  setVideoUrl(url);
                }
              }}
            >
              {index + 1}
            </div>
          ))}
        </>
      ) : error ? (
        <p className="error">Загрузите формат видеофайла</p>
      ) : (
        <p>Загрузите видео</p>
      )}
    </div>
  );
};

export default VideoContainer;

import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { RiMenuFold2Line } from 'react-icons/ri';
import { CgPlayList, CgChevronRight } from 'react-icons/cg';
import { TbIndentIncrease, TbDotsVertical } from 'react-icons/tb';

import Menu from './components/Menu/Menu.js';
import Home from './pages/Home/Home.js';
import Admin from './pages/Admin/Admin.js';
import './App.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [circlesData, setCirclesData] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="app">
        <button className="menu-button" onClick={toggleMenu}>
          <span className="rectangle"></span>
          <span className="circle">
            <TbIndentIncrease />
          </span>
        </button>
        {/* <Menu className={menuOpen ? 'menu-open' : 'menu'} /> */}
        <Menu menuOpen={menuOpen} closeMenu={() => setMenuOpen(false)} />
        {/* {menuOpen && ()} */}
        <Routes>
          <Route path="/" element={<Home circlesData={circlesData} />} />
          <Route
            path="/admin"
            element={<Admin setCirclesData={setCirclesData} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
